<template>
  <div class="file-meta-editor form-row ">
    <h5 class="col-12">
      {{ $t('file_meta_data.header') }}
    </h5>
    <div class="col-12">
      <form-input :label="$t('file_meta_data.file_title')"
                  :value="value.name"
                  :max-length="100"
                  @input="onInput('name', $event)"
      />
    </div>
    <div class="col-12 mb-3">
      <list-picker
          :label="$t('file_meta_data.tags.label')"
          :add-button-title="$t('file_meta_data.tags.add_tag_to_file')"
          :remove-button-title="$t('file_meta_data.tags.remove_tag_from_file')"
          :create-button-text="$t('file_meta_data.tags.create_button')"
          :search-placeholder="$t('file_meta_data.tags.search_placeholder')"
          :all="tags"
          :value="value.tags"
          item-prefix="#"
          @input="onInput('tags', $event)"
          @create="$emit('create-tag', $event)"
      />
    </div>
    <div class="col-12">
      <list-picker
          :label="$t('file_meta_data.directories.label')"
          :add-button-title="$t('file_meta_data.directories.add_tag_to_file')"
          :remove-button-title="$t('file_meta_data.directories.remove_tag_from_file')"
          :create-button-text="$t('file_meta_data.directories.create_button')"
          :search-placeholder="$t('file_meta_data.directories.search_placeholder')"
          :all="directories"
          :value="value.directories"
          item-prefix='<i class="fa-solid fa-folder pr-1"></i>'
          @input="onInput('directories', $event)"
          @create="$emit('create-directory', $event)"
      />
    </div>
  </div>
</template>

<script>
import FormInput from "@pixelstein/ps-form/components/PsFormInput";
import ListPicker from "@/components/ListPicker";

export default {
  name: "FileMetaEditor",
  components: {
    ListPicker,
    FormInput,
  },
  props: {
    selectedDirectory: {type: Array, default: () => []},
    directories: {type: Array, default: () => []},
    tags: {type: Array, default: () => []},
    value: {type: Object, default: () => ({})},
  },
  computed: {
    directoryFilter() {
      if (!this.directories) {
        return []
      }
      let newArray = []
      this.directories.forEach(el => {
        newArray.push({id: el.id, name: el.name})
      })
      return newArray
    }
  },
  methods: {
    onInput(key, value) {
      let nv = {...this.value}
      nv[key] = value
      this.$emit('input', nv)
    },
    addDirectory(val) {
      let metadata = {...this.value}
      metadata.id = this.file_id
      metadata.directories = val
      metadata.change = "directories"
      this.$emit('input', metadata)
    },
  }
}
</script>
