import { render, staticRenderFns } from "./FormContentComposerOptionsText.vue?vue&type=template&id=8b5f16be&scoped=true"
import script from "./FormContentComposerOptionsText.vue?vue&type=script&lang=js"
export * from "./FormContentComposerOptionsText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b5f16be",
  null
  
)

export default component.exports