<template>
  <div class="file-directory-menu">
    <div class="directions-outer-wrap">
      <div class="directories-wrap">
        <label class="directory"
               :class="{active: '' === value, muted: false}">
          <input type="radio" name="directories" @change="$emit('input', '')">
          {{ allLabel }}
        </label>
        <label v-for="directory in directories"
               :key="directory.id"
               class="directory"
               :class="{active: directory.id === value}">
          <input type="radio" name="directories" @change="$emit('input', directory.id)">
          {{ directory.name }}
        </label>
      </div>

      <a href="#"
         class="add-btn"
         :title="$t('directory_control.edit_directories')"
         @click.prevent="editDirectories"
         v-if="!readonly"
      >
        <svg-icon name="settings"/>
      </a>
    </div>

    <edit-entity-modal v-if="showModal"
                       :modal-title="$t('directory_control.edit_directory')"
                       :unique-invalid-info="$t('directory_control.directory_name_must_be_unique')"
                       :required-invalid-info="$t('directory_control.directories_requires_names')"
                       :add-new-btn-label="$t('directory_control.edit.add_directory_button')"
                       :cancel-btn-label="$t('directory_control.edit.cancel_button')"
                       :save-btn-label="$t('directory_control.edit.save_button')"
                       :items="clonedDirectories"
                       :required-values="['name']"
                       :required-unique-values="['name']"
                       :add-new-item="true"
                       @showModal="showModal = $event"
                       @save="save()"
                       @add="clonedDirectories.push($event)"
    >
      <template #formInputSlot="{item, items}">
        <div class="flex-grow-1">
          <form-input
              :label="$t('directory_control.edit.add_directory_placeholder')"
              required
              :invalid-note="$t('directory_control.directory_name_invalid')"
              v-model="item.name"
              :max-length="100"
          />
          <div class="text-warning small mt-n3"
               :class="{invisible: (!items.find(dir => dir.name.trim() === item.name?.trim() && dir.id !== item.id) || item.name?.trim().length === 0)}"
          >
            {{ $t('directory_control.new.name_already_in_use') }}
          </div>
        </div>
      </template>
      <template #formInputAddItemSlot="{newItem, items}">
        <div class="flex-grow-1">
          <form-input
              :label="$t('directory_control.new.directory_label')"
              required
              :invalid-note="$t('directory_control.directory_name_invalid')"
              v-model="newItem.name"
              :max-length="100"
          />
          <div class="text-warning small mt-n3"
               :class="{invisible: (!items.find(dir => dir.name.trim() === newItem.name?.trim()) || newItem.name?.trim().length === 0)}"
          >
            {{ $t('directory_control.new.name_already_in_use') }}
          </div>
        </div>
      </template>
    </edit-entity-modal>
  </div>
</template>

<script>

import _cloneDeep from 'lodash/cloneDeep'

import SvgIcon from '@/components/SvgIcon'
import EditEntityModal from '@/components/EditEntityModal'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'

export default {
  name: 'FileDirectoryMenu',
  components: {
    SvgIcon,
    EditEntityModal,
    FormInput,
  },
  props: {
    directories: { type: Array, default: () => [] },
    directory: { type: Object, default: () => ({}) },
    value: { type: String, default: '' },
    allLabel: { type: String, default: 'All Files' },
    readonly: { type: Boolean, default: false },
  },
  data () {
    return {
      showModal: false,
      clonedDirectories: [],
      newDirectory: { name: '' },
    }
  },
  methods: {
    editDirectories () {
      this.clonedDirectories = _cloneDeep(this.directories)
      this.showModal = true
    },
    save () {
      const onSuccessCallback = () => {
        this.showModal = false
        this.clonedDirectories = []
      }

      const directoriesToUpdate = this.clonedDirectories
          .filter(dir => !dir.delete
              && !dir.new,
          )

      const directoriesToDelete = this.clonedDirectories
          .filter(dir => dir.delete && !dir.new)

      const directoriesToAdd = this.clonedDirectories
          .filter(dir => !dir.delete && dir.new)

      this.$emit('update-directories', {
        directoriesToUpdate,
        directoriesToDelete,
        directoriesToAdd,
        onSuccessCallback,
      })
    },
    deleteDirectory (id) {
      const directoryIdx = this.clonedDirectories.findIndex(dir => dir.id === id)
      this.clonedDirectories.splice(directoryIdx, 1)
    },
  },
}
</script>
