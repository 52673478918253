<template>
  <div v-if="!lock" class="statistics-form view">
    <div class="row h-100">
      <div :class="{'col-8': action==='edit', 'col-12': action==='view' }"
           class="chart-preview-column h-100 overflow-auto">
        <div class="sticky-top bg-white pt-2">
          <router-link-back :to="'/statistics/'+reportConfigId">
            {{ chart.name || $t('statistics_form.unnamed') }}
          </router-link-back>
        </div>
        <div v-if="action === 'view'" class="col text-right">
          <button class="btn btn-primary" @click="redirectToEdit">{{ $t('statistics_list.edit') }}</button>
        </div>

        <StatisticChart
            ref="statistic_chart"
            :data="chart.report_configs.flatMap(rc => rc.reports)"
            :report-fields="reportFields"
            :chart-config="chart"
            :dataset-id-key="labelForField(chart?.config?.chart_value)"
            :label="chart.name"
            :chart-type="chart.type"
        />
      </div>
      <div v-if="action==='edit'" class="col-4 chart-editor-column h-100 overflow-auto">
        <statistic-settings
            :report-fields="reportFields"
            :report-configurations="reportConfigs"
            v-model="chart"

            @save="onSave"
            @cancel="$router.push(['/statistics', reportConfigId].join('/')).catch(() => null)"
            @remove="onRemoveDataset"
        />
      </div>
    </div>

    <dirty-modal
        ref="dirty"
        :entity="chart"
        :title="$t('statistics_form.dirty.title')"
        :body="$t('statistics_form.dirty.body')"
        :save-label="$t('statistics_form.dirty.save')"
        :dont-save-label="$t('statistics_form.dirty.discard')"
        @save="onSave"
    />
  </div>
  <loading-screen v-else></loading-screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import StatisticChart from '@/components/Statistics/StatisticsChart'
import StatisticSettings from '@/components/Statistics/StatisticSettings'
import _cloneDeep from 'lodash/cloneDeep'
import RouterLinkBack from '@/components/RouterLinkBack'
import LoadingScreen from '@/components/LoadingScreen'
import DirtyModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalDirtyModal'
import { flattenFields } from 'paperclip-lib/src/report-configs/ReportConfigUtils'

export default {
  name: 'StatisticsForm',
  props: {
    reportConfigId: { type: String, default: null },
    chartId: { type: String, default: null },
    action: { type: String, default: 'edit' },
  },
  components: {
    LoadingScreen,
    RouterLinkBack,
    StatisticChart,
    StatisticSettings,
    DirtyModal,
  },
  data () {
    return {
      lock: true,
      chart: null,
      reports: [],
    }
  },
  computed: {
    ...mapState({
      reportConfig: state => state.Api.ReportConfigs.current,
      reportConfigs: state => state.Api.ReportConfigs.all,
    }),
    filteredReportData () {
      if (!this.chart.report_configs) {
        return []
      }
      let result = this.reports

      Object.entries(this.chart.config.filter || {})
          .forEach(([key, filterValues]) => {
            result = result.filter(report => {
              const reportValue = report.data[key]

              if (typeof reportValue === 'string') {
                return filterValues.find(fv => fv === reportValue)

              } else if (Array.isArray(reportValue)) {
                return filterValues.some(fv => reportValue.find(rv => rv === fv))

              } else if (typeof reportValue === 'object') {
                return filterValues.some(fv => Object.values(reportValue).find(rv => rv === fv))

              }
            })
          })

      return result
    },
    filteredChartData () {
      let data = {}
      this.filteredReportData.forEach(report => {
        const barLabel = report.data[this.chart.config.chart_value] || 'N/A'
        if (typeof barLabel === 'object') {
          for (let key in barLabel) {
            if (data[key] === undefined) {
              data[key] = 0
            }

            data[key]++
          }
        } else {
          if (data[barLabel] === undefined) {
            data[barLabel] = 0
          }

          data[barLabel]++
        }

      })

      return data
    },
    reportFields () {
      return this.chart.report_configs
          .flatMap(rc => flattenFields(rc.fields))
          .filter((field, idx, array) => array.findIndex(
              f => (f?.options?.name || f?.options?.key) === (field?.options?.name || f?.options?.key)) === idx)
    },
  },
  mounted () {
    this.getReportConfigs({
      limit: 100,
      contain: ['reports'],
    })
        .catch(this.apiErrorHandler)

    if (this.chartId) {
      this.getChart({
        id: this.chartId,
        contain: ['report_configs'], // TODO: add contain , 'report_configs.reports'
      })
          .then(chartConfig => {
            this.chart = _cloneDeep(chartConfig)
            let promise = Promise.resolve()
            chartConfig.report_configs
                .forEach(reportConfig => {
                  promise = promise
                      .then(() => this.getReportConfig({
                        id: reportConfig.id,
                        contain: ['reports'],
                      }))
                      .then(rc => {
                        const idx = this.chart.report_configs.findIndex(c => c.id === reportConfig.id)
                        this.chart.report_configs.splice(idx, 1, rc)

                      })
                })
            return promise
          })
          .catch(this.apiErrorHandler)
          .finally(() => {
            this.lock = false
          })
    } else {
      // new
      this.getReportConfig({
        id: this.reportConfigId,
        contain: ['reports'],
      }).then(result => {
        this.reports = [...this.reports, ...result.reports]
        this.chart = {
          name: 'new chart',
          report_configs: [result],
          type: 'bar',
          config: {},
        }

      })
          .catch(this.apiErrorHandler)
          .finally(() => {
            this.lock = false
          })
    }
  },
  methods: {
    ...mapActions({
      getReportConfig: 'Api/ReportConfigs/view',
      getReportConfigs: 'Api/ReportConfigs/index',
      getChart: 'Api/Charts/view',
      addChart: 'Api/Charts/add',
      updateChart: 'Api/Charts/edit',
    }),
    labelForField (key) {
      return this.reportFields.find(field => field?.options?.key === key)?.options?.label
    },
    onSave () {
      this.$refs?.dirty.acceptNavigation()

      if (this.chartId) {
        this.updateChart(this.chart)
            .then(() => this.$router.push(['/statistics', this.reportConfigId, 'view', this.chartId].join('/')))
      } else {
        this.addChart(this.chart)
            .then(chart => this.$router.push(['/statistics', this.reportConfigId, 'view', chart.id].join('/')))
      }
    },
    onRemoveDataset (e) {
      this.chart.config.datasets.splice(e, 1)
    },
    redirectToEdit () {
      this.$router.push(['/statistics', this.reportConfigId, 'edit', this.chartId].join('/')).catch(() => null)
    },
  },
}
</script>
