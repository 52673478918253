<template>
  <div v-if="formConfig" class="report-editor view pt-1">
    <div class="row pl-1 bg-extra-light">
      <div class="col-lg-8 report-column">
        <div class="sticky-top bg-extra-light px-3 mx-n3 pb-1 pt-2 d-flex justify-content-between align-items-center">
          <router-link-back :to="'/reports/' + (reportConfigId ?? '')">
            {{ $t('navigation.modules.reports') }}
          </router-link-back>
        </div>
        <h3 class="text-uppercase mb-4 mt-3">{{ formConfig.name || $t('report_editor.unnamed_form') }}</h3>
        <div class="alert alert-warning" role="alert" v-if="reportHandlersCount > 0">
          {{ $tc('report_editor.report_handler_warning', reportHandlersCount, { count: reportHandlersCount }) }}
        </div>
        <form-configured-content
            ref="form_content"
            :contents="basedFields"
            :content-title="$t('report_editor.grab_or_click')"
            v-model="demo"
            enable-drag-n-drop
            @drag-item-start="draggingElement = $event"
            @click="editContent = $event"
        >

          <template #beforeContent="{content, contentIndex, dragOver, container}">
            <div class="dropzone">
              <div class="dropzone-inner before"
                   @drop.stop="onDrop(draggingElement|| preparedContent, dragOver, container, false)">
                {{
                  $t('report_editor.dropzone_before',
                      { content_name: content.options.label || content.options.text || content.type })
                }}
              </div>
            </div>
          </template>

          <template #afterContent="{content, contentIndex, dragOver, container}">
            <div class="dropzone">
              <div class="dropzone-inner after" @drop.stop="onDrop(draggingElement|| preparedContent, dragOver, container, true)">
                {{
                  $t('report_editor.dropzone_after',
                      { content_name: content.options.label || content.options.text || content.type })
                }}
              </div>
            </div>
          </template>

        </form-configured-content>
        <general-modal
            v-if="editContent"
            :close-on-backdrop="false"
            @close="editContent= null"
        >
          <form-content-composer
              v-model="editContent"
              :content-keys="contentKeys"
              edit-mode
              @input="uploadImages"
              @save="onSaveContent"
          />
          <template #footer>
            <button
                type="button"
                class="btn btn-danger"
                @click="removeContent(editContent.id, formConfig.fields); editContent = null"
            >
              {{ $t('report_editor.delete_content') }}
            </button>
          </template>
        </general-modal>
      </div>
      <div class="col-lg-4 tool-box-column">
        <form-radio class="tag text-uppercase sticky-top bg-white" :options="toolboxTabs" v-model="toolboxTab"/>
        <form v-if="formConfig" :class="{'d-none': toolboxTab !== 'form_settings'}" @submit.prevent="saveForm">
          <h5 class="text-uppercase">{{ $t('report_editor.form_settings_header') }}</h5>
          <form-input
              :label="$t('report_editor.report_name_label')"
              required
              v-model.trim="formConfig.name"
          />

          <ps-accordion-collapse class="mb-2 bg-white">
            <template #header="{active}">
              <div class="p-2 d-flex align-items-center justify-content-between">
                {{ $t('report_editor.states_label') }}
                <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
              </div>
            </template>
            <template #content>
              <div class="p-2">
                <div v-for="(option, idx) in formConfig.states"
                     :key="option.id"
                     class="form-row border-light border-bottom">
                  <div class="col-12 text-right small pt-1">
                    <button type="button"
                            class="btn btn-sm btn-outline-danger mb-n3"
                            @click="removeState(idx)"
                    ><i class="fas fa-trash" aria-hidden="true"></i></button>
                  </div>
                  <div class="col-6">
                    <form-input
                        :label="$t('form_content_composer.options_input.options.value_label')"
                        :value="option.value"
                        @input="onInput(['states', idx, 'value'], $event)"
                    />
                  </div>
                  <div class="col-6">
                    <form-input
                        :label="$t('form_content_composer.options_input.options.label_label')"
                        :value="option.label"
                        @input="onInput(['states', idx, 'label'], $event)"
                    />
                  </div>
                </div>

                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="onInput(['states', formConfig.states?.length || 0], getEmptyOption())"
                >
                  {{ $t('form_content_composer.options_input.options.add_button') }}
                </button>
              </div>
            </template>
          </ps-accordion-collapse>

          <ps-accordion-collapse class="my-2 bg-white">
            <template #header="{active}">
              <div class="p-2 d-flex align-items-center justify-content-between">
                {{ $t('report_editor.list_columns') }}
                <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
              </div>
            </template>
            <template #content>
              <form-list-column-composer class="p-2" v-model="formConfig.fields"/>
            </template>
          </ps-accordion-collapse>

          <div class="bg-white p-2 my-2">
            <div class="mb-2">
              {{ $t('report_editor.suggestion_file_label') }}
            </div>

            <multi-file-upload
                :button-label-html-first-item="$t('report_editor.suggestions')"
                :allowed-file-extensions="['.db', '.sqlite']"
                :max-file-count="1"
                :value="[formConfig.suggestion_file].filter(el => !!el)"
                @input="uploadSuggestions"
            />

          </div>

          <div class="form-row mt-5">
            <div class="col-6">
              <button-confirmation
                  :form-mode="false"
                  :disabled="saving"
                  :modal-title="$t('report_editor.delete_modal.title')"
                  :modal-body="$t('report_editor.delete_modal.body', {name: this.formConfig.name})"
                  :modal-yes="$t('report_editor.delete_modal.yes')"
                  :modal-no="$t('report_editor.delete_modal.no')"
                  @click="tryDelete"
              >
                {{ $t('report_editor.delete') }}
              </button-confirmation>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-secondary" :disabled="saving">
                {{ $t('report_editor.save') }}
              </button>
            </div>
          </div>
        </form>
        <div :class="{'d-none': toolboxTab !== 'create_content'}">
          <h5 class="text-uppercase">{{ $t('report_editor.create_content_header') }}</h5>
          <form-content-composer v-model="preparedContent" :content-keys="contentKeys" @input="uploadImages"/>
        </div>
      </div>
    </div>
    <dirty-modal
        ref="dirty"
        :entity="formConfig"
        :title="$t('report_editor.dirty.title')"
        :body="$t('report_editor.dirty.body')"
        :save-label="$t('report_editor.dirty.save')"
        :dont-save-label="$t('report_editor.dirty.discard')"
        @save="saveForm"
    />
  </div>
  <loading-screen v-else/>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import { v1 } from 'uuid'

import { mapActions, mapGetters } from 'vuex'

import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'

import MultiFileUpload from '@/components/Reports/MultiFileUpload.vue'
import FormContentComposer from '@/components/Reports/FormContentComposer'
import FormListColumnComposer from '@/components/Reports/FormListColumnComposer'

import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import ButtonConfirmation from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalButtonConfirmationModal'
import DirtyModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalDirtyModal'

import LoadingScreen from '@/components/LoadingScreen'
import DefaultFieldConfig from '@/assets/defaultReportConfig.json'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import { flattenFields, traverseFields } from 'paperclip-lib/src/report-configs/ReportConfigUtils.js'

export default {
  name: 'ReportEditor',
  components: {
    RouterLinkBack,
    LoadingScreen,
    FormListColumnComposer,
    FormContentComposer,
    FormConfiguredContent,
    FormInput,
    FormRadio,
    GeneralModal,
    MultiFileUpload,
    PsAccordionCollapse,
    ButtonConfirmation,
    DirtyModal,
  },
  props: {
    reportConfigId: { type: String, default: null },
  },
  watch: {
    formConfig: {
      deep: true,
      handler (nv) {
        if (nv?.fields.length === 0) {
          this.formConfig.fields.push(this.defaultContent)
          this.saveForm()
          this.loadFormConfig()
        }
      }
    }
  },
  data () {
    return {
      formConfig: DefaultFieldConfig,
      demo: {},
      draggingElement: null,
      preparedContent: undefined,
      editContent: null,
      toolboxTab: 'form_settings',
      saving: false,
      defaultContent: {
        type: 'formatString',
        column: 'col-12',
        options: { text: 'default container content' },
      },
      defaultInputContent: {
        column: 'col-12',
        type: "input",
        options: {
          type:"text",
          label: "Default Input",
          name: "Default Input",
          required: false,
          invalidNote: ".."
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      findReportConfig: 'Api/ReportConfigs/find',
    }),
    toolboxTabs () {
      return [
        { value: 'form_settings', label: this.$t('report_editor.tab.form_settings') },
        { value: 'create_content', label: this.$t('report_editor.tab.create_content') },
      ]
    },
    reportHandlersCount () {
      return (this.formConfig.report_handlers ?? []).length
    },
    contentKeys () {
      return flattenFields(this.formConfig.fields)
          .map(cont => {
            return cont.options.name ?? cont.options.key
          })
          .filter(k => !!k)
    },
    basedFields() {
      let fields = _cloneDeep(this.formConfig.fields)
      fields.map(field => {
        if (field.type === 'image' && !field.options.file.includes(this.$config.API_BASE_URL)) {
          field.options.file = this.$config.API_BASE_URL + field.options.file
          field.options.thumbnail = this.$config.API_BASE_URL + field.options.thumbnail
        }
        return field
      })
      return fields
    }
  },
  methods: {
    ...mapActions({
      addReportConfig: 'Api/ReportConfigs/add',
      updateReportConfig: 'Api/ReportConfigs/edit',
      getReportConfig: 'Api/ReportConfigs/view',
      addFile: 'Api/Files/add',
      deleteReportConfig: 'Api/ReportConfigs/delete',
    }),
    cloneContent (draggingContent) {
      draggingContent = _cloneDeep(draggingContent)
      draggingContent.id = v1()

      if (Array.isArray(draggingContent.options?.contents)) {
        draggingContent.options.contents = draggingContent.options.contents
            .map(content => this.cloneContent(content))
      }

      return draggingContent
    },
    onDrop (draggingContent, dragOver, parentContainer, append = true) {
      const draggedHasAutoComplete = draggingContent.type === 'auto_complete_container' || flattenFields(draggingContent.options.contents).flatMap(container => container).filter(field => ['auto_complete_container'].includes(field.type)).length > 0
      const insertContainerIsAutoComplete = dragOver.type === 'auto_complete_container' || flattenFields(this.formConfig.fields).flatMap(container => container).filter(field => field.id == parentContainer?.id && ['auto_complete_container'].includes(field.type)).length > 0
      if (parentContainer && draggedHasAutoComplete && insertContainerIsAutoComplete) {
        this.clearDragOptions()
        this.draggingElement = null
        this.$toast.open({
          message: this.$t('report_editor.nested_auto_complete_error'),
          type: 'error',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })
        return;
      }
      if (!draggingContent.id) {
        draggingContent = this.cloneContent(draggingContent)
      }

      if (draggingContent.type === 'image' && !draggingContent.options.file.includes(this.$config.API_BASE_URL)) {
        draggingContent.options.file = this.$config.API_BASE_URL + draggingContent.options.file
        draggingContent.options.thumbnail = this.$config.API_BASE_URL + draggingContent.options.thumbnail
      }

      const updatedFields = this.removeContent(draggingContent.id, this.formConfig.fields)
      this.$set(this.formConfig, 'fields', updatedFields)

      this.updateKey(draggingContent)

      if (draggingContent.options.contents) {
        traverseFields(draggingContent.options.contents, field =>
        {
          this.updateKey(field)
        })
      }

      this.insertContent(this.formConfig.fields, dragOver, draggingContent, append)
      this.clearDragOptions()
      this.draggingElement = null
    },
    updateKey(field) {
      if (this.contentKeys.find(key => key === field.options.name)) {
        let count = this.contentKeys.filter(key => key.startsWith(field.options.name)).length
        field.options.name = field.options.name + count
        this.$toast.open({
          message: this.$t('report_editor.double_key_count', {new: field.options.name}),
          type: 'warning',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })
      }
    },
    clearDragOptions () {
      this.$nextTick().then(this.$refs.form_content.clearDragOver)
    },
    insertContent (contents, searchForContent, newContent, append) {
      const idx = contents.findIndex(c => c.id === searchForContent.id)
      console.log('insertContent', idx, searchForContent)
      if (idx === -1) {
        contents
            .filter(c => Array.isArray(c?.options?.contents))
            .forEach(c => {
              console.log('search in sub content', c.options.contents)
              this.insertContent(c.options.contents, searchForContent, newContent, append)
            })
      } else {
        console.log('found !')
        contents.splice(idx + append, 0, newContent)
      }
    },
    async uploadImages(imgObj) {
      if(imgObj && imgObj.type === "image" && imgObj.options?.progressingFiles) {

        try {
          const file = await this.addFile({
            ...imgObj.options.progressingFiles[0],
            $uploadProgressCallback: p => {
              imgObj.options.onProgress(0, (p.loaded / p.total * 100))
              imgObj.options.onSuccessCallback(0)
              imgObj.options.onFinallyCallback(0)
            }
          })

          imgObj.options = file

        } catch (error) {
          this.apiErrorHandler(error)
        }
      }
    },
    async uploadSuggestions (fileObj) {
      if (!fileObj || fileObj.length === 0) {
        this.$set(this.formConfig, 'suggestion_file_id', null)
        this.$set(this.formConfig, 'suggestion_file', null)
        return
      }

      try {
        const file = await this.addFile({
          ...fileObj.progressingFiles[0],
          title: 'suggestions',
          $uploadProgressCallback: p => {
            fileObj.onProgress(0, (p.loaded / p.total * 100))
          },
        })
        this.formConfig.suggestion_file_id = file.id
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
    onSaveContent () {
      this.$set(this.formConfig, 'fields', this.updateContent(this.formConfig.fields, this.editContent))
      this.editContent = null
    },
    updateContent (fields, content) {
      if (Array.isArray(fields)) {
        return fields.map(field => {
          if (field && field?.id === content?.id) {
            return content
          }

          if (field && field?.options?.contents) {
            field.options.contents = this.updateContent(field.options.contents, content)
          }

          return field
        })
      }

      return fields
    },
    removeContent (contentId, fields) {
      const contentIdx = fields.findIndex(field => field.id === contentId)
      if (contentIdx === -1) {
        fields = fields.map(field => {
          if (field?.options?.contents) {
            field.options.contents = this.removeContent(contentId, field.options.contents)
            if (['layout_row', 'collapse_container', 'condition_container'].includes(field.type) && field.options.contents.length === 0) {
              field.options.contents.push({...this.defaultContent, id: v1()})
            }
            if (['auto_complete_container'].includes(field.type) && field.options.contents.length === 0) {
              field.options.contents.push({...this.defaultContent, id: v1()})
            }
          }
          return field
        })
      } else {
        fields.splice(contentIdx, 1)
      }
      return fields
    },
    tryDelete () {
      this.$refs.dirty.acceptNavigation()

      if (!this.reportConfigId) {
        this.$router.replace('/reports').catch(() => null)
        return
      }

      this.deleteReportConfig({
        id: this.reportConfigId,
      })
          .then(() => {
            this.$router.replace('/reports').catch(() => null)
          })
          .catch(this.apiErrorHandler)
    },
    saveForm () {
      if (this.saving) {
        return
      }
      this.saving = true

      this.$refs.dirty.acceptNavigation()

      let config = _cloneDeep(this.formConfig)

      if (this.reportConfigId) {
        this.updateReportConfig(config)
            .catch(this.apiErrorHandler)
            .finally(() => {
              this.saving = false
            })
      } else {
        this.addReportConfig(config)
            .then(result => this.$router.push('/reports/config/' + result.id))
            .catch(this.apiErrorHandler)
            .finally(() => {
              this.saving = false
            })
      }
    },
    addIds (fields) {
      if (Array.isArray(fields)) {
        fields = fields.map(field => {
          if (field?.options?.contents) {
            field.options.contents = this.addIds(field.options.contents)
          }

          if (!field.id) {
            field.id = v1()
          }

          return field
        })
      }

      return fields
    },
    removeState (idx) {
      this.formConfig.states.splice(idx, 1)
    },
    onInput (path, value) {
      const clone = _cloneDeep(this.formConfig)
      _set(clone, path, value)
      this.formConfig = clone
      this.$forceUpdate()
    },
    getEmptyOption () {
      return { label: '', value: '', id: v1() }
    },
    loadFormConfig() {
      this.formConfig.fields = this.addIds(this.formConfig.fields)

      if (this.reportConfigId) {
        this.formConfig = null
        this.getReportConfig({
          contain: ['suggestion_file', 'report_handlers'],
          id: this.reportConfigId,
        })
            .then(config => {
              this.formConfig = _cloneDeep(config)
              this.formConfig.fields = this.addIds(this.formConfig.fields)

              if (this.formConfig.suggestion_file?.file) {
                this.formConfig.suggestion_file.file = this.$config.API_BASE_URL + this.formConfig.suggestion_file.file
              }

              if (this.formConfig.suggestion_file?.thumbnail) {
                this.formConfig.suggestion_file.thumbnail = this.$config.API_BASE_URL +
                    this.formConfig.suggestion_file.thumbnail
              }

            })
            .catch(this.apiErrorHandler)
      }
    }
  },
  created () {
    this.loadFormConfig()
  },
  mounted () {
    window.addEventListener('drop', this.clearDragOptions)
    window.addEventListener('dragend', this.clearDragOptions)
  },
  beforeDestroy () {
    window.removeEventListener('drop', this.clearDragOptions)
    window.removeEventListener('dragend', this.clearDragOptions)
  },
}
</script>
