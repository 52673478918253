<template>
  <div class="tag-filter" :class="{active}" :style="{height}">

    <div ref="tag_wrap" class="tag-wrap">
      <form-checkbox
          v-for="tag in tags"
          :key="tag.name"
          :label="'#'+tag.name"
          class="tag mb-1"

          :value="value.find(({id}) => tag.id === id)"
          @input="setActive(tag)"
      />
    </div>
    <transition name="fade">
      <button v-if="overload"
              type="button"
              class="filter-toggle"

              @click="active = !active"
      >
        <i class="icon fa-solid fa-chevron-left"
           aria-hidden="true"
        />
      </button>
    </transition>

  </div>
</template>

<script>

import FormCheckbox from 'pixelstein-vue-app-package/src/vue2/PsForm/PsFormCheckbox'
import _cloneDeep   from 'lodash/cloneDeep';

export default {
  name: 'TagFilter',
  components: {
    FormCheckbox,
  },
  props: {
    tags: {type: Array, default: () => []},
    value: {type: Array, default: () => []},
  },
  watch: {
    tags() {
      this.$nextTick(this.setOverload);
    },
    active(nv) {
      if (!nv) {
        this.$refs
            .tag_wrap
            .scroll({
              y: 0,
              x: 0,
              behavior: 'smooth',
            });
      }
    },
  },
  data() {
    return {
      wrapHeight: 'auto',
      tagHeight: 'auto',
      active: false,
      overload: false,
    };
  },
  computed: {
    height() {
      return this.active
             ? `${this.wrapHeight}px`
             : `${this.tagHeight}px`
          ;
    },
  },
  mounted() {
    this.$nextTick(this.setOverload);
    window.addEventListener('resize', this.setOverload);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setOverload);
  },
  methods: {
    setOverload() {
      this.wrapHeight = this.$refs.tag_wrap.clientHeight;
      this.tagHeight = this.$refs.tag_wrap.childNodes[0]?.clientHeight;
      const overloadMinHeight = this.tagHeight * 1.5;

      this.overload = this.wrapHeight > overloadMinHeight;
    },
    setActive(tag) {
      const index = this.value.findIndex(({id}) => id === tag.id);
      const valueClone = _cloneDeep(this.value);

      if (index === -1) {
        valueClone.push(tag);
        this.$emit('input', valueClone);

      } else {

        valueClone.splice(index, 1);

        this.$emit('input', valueClone);

      }
    },
  },
};
</script>

